import {Controller} from "stimulus"
import $ from 'jquery';

export default class extends Controller {
    connect() {
        $("#report_type").on('select2:select', function () {
            let event = new Event('change', { bubbles: true })
            this.dispatchEvent(event);
        });
    }

    showOperationReport(e){
        let report_nom = $("#report_type").val();
        this.cleanOperationsComponents();
        switch (report_nom){
            case 'amount_operations_by_date':
                $('#div_businesses').show();
                $('#businesses').prop('required',false);
                $("#div_report_date").show();
                $('#report_date').prop('required',true);
                break;
            case 'amount_day_operations':
                $('#div_businesses').show();
                $('#businesses').prop('required',false);
                $("#div_multi_agencies").show();
                $('#basic_businesses').prop('required',true);
                $("#div_report_date").show();
                $('#report_date').prop('required',true);
                break;
        }
    }

    /**
     * Para los reportes de la tecnica
     * */
    showFleetTypeByReportType(e){
        let report_type = $("#report_type").val();
        // Ocultando antes cada cambio
        this.hideCleanComponents();

        switch (report_type){
            case 'resume_business_vehicles_type':
                $("#div_group_fleet").show();
                $('#label_group_fleet').removeClass('required');
                $("#div_fleet_type").show();
                $('#label_fleet_type').removeClass('required');
                $("#div_report_date").show();
                $('#report_date').prop('required',true);
                break;
            case 'park_model_group_fleet':
                $("#div_group_fleet").show();
                $('#group_fleet').prop('required',true);
                $("#div_report_date").show();
                $('#report_date').prop('required',true);
                break;
            case 'stolen_vehicle_relationship':
                $("#div_report_date").show();
                $('#report_date').prop('required',true);
                break;
            case 'list_vehicles_active_fleet':
                $("#div_report_date").show();
                $('#report_date').prop('required',true);
                break;
            case 'registrations_month':
                $("#div_range_date").show();
                $('#min_date').prop('required',true);
                $('#max_date').prop('required',true);
                break;
            case 'internal_transfers':
                $("#div_range_date").show();
                $('#min_date').prop('required',true);
                $('#max_date').prop('required',true);
                break;
            case 'transfers_between_businesses':
                $("#div_range_date").show();
                $('#min_date').prop('required',true);
                $('#max_date').prop('required',true);
                break;
            case 'unsubscribe_month':
                $("#div_range_date").show();
                $("#div_unsubscribe_type").show();
                $('#min_date').prop('required',true);
                $('#max_date').prop('required',true);
                break;
            case 'vehicle_with_plate_changes':
                $("#div_report_date").hide();
                break;
            default:
                $("#div_report_date").show();
                $('#report_date').prop('required',true);
                break;
        }
    }

    hideCleanComponents() {
        // Grupo de Flota
        $("#div_group_fleet").hide();
        $('#label_group_fleet').addClass('required');
        $('#group_fleet').val(null).trigger('change');
        $('#group_fleet').prop('required', false);
        // Tipo de Flota
        $("#div_fleet_type").hide();
        $('#label_fleet_type').addClass('required');
        $('#fleet_type').val(null).trigger('change');
        $('#fleet_type').prop('required',false);
        //Report date
        $("#div_report_date").hide();
        $('#report_date').prop('required',false);
        //Rango de fechas
        $("#div_range_date").hide();
        $('#min_date').prop('required',false);
        $('#max_date').prop('required',false);
        //Causa de baja
        $("#div_unsubscribe_type").hide();
        $('#unsubscribe_type').prop('required',false);
    }

    cleanOperationsComponents(){
        $("#div_businesses").hide();
        $('#businesses').val(null).trigger('change');
        $('#businesses').prop('required',false);
        $("#div_multi_agencies").hide();
        $('#basic_businesses').val(null).trigger('change');
        $('#basic_businesses').prop('required',false);
        $("#div_basic_business").hide();
        $('#basic_business').val(null).trigger('change');
        $('#basic_business').prop('required',false);
    }

    /**
     * Para los reportes de Economia, habilitar o no los componentes segun tipo de reporte
     * */
    enableEconomyComponentsByReportType(){
        let business = $("#business_id");
        let travel_agency = $("#travel_agency");
        let frame_agency = $("#frame_agency");
        let basic_business = $("#basic_business_id");
        let rent_lot = $("#rent_lot_id");
        let contract_code = $("#contract_code");
        let rent_adjustment = $("#rent_adjustment");
        let modality = $("#modality");
        let legal_contract = $("#legal_contract");
        let bill_state = $("#bill_state");

        let report_type = $("#report_type").val();
        this.defaultEconomyComponents();
        switch (report_type){
            case 'consolidate_lots':
                business.prop('disabled', false);
                business.prop('required', true);
                $("#label_business").addClass('required');
                basic_business.prop('disabled', false);
                rent_lot.prop('disabled', false);
                rent_adjustment.prop('disabled', false);
                travel_agency.val(null).trigger('change');
                travel_agency.prop('disabled', true);
                frame_agency.val(null).trigger('change');
                frame_agency.prop('disabled', true);
                contract_code.val(null).trigger('change');
                contract_code.prop('disabled', true);
                modality.prop('disabled', true);
                legal_contract.prop('disabled', true);
                bill_state.prop('disabled', true);
                break;
            case 'balance_resume':
                business.val(null).trigger('change');
                basic_business.val(null).trigger('change');
                rent_lot.val(null).trigger('change');
                travel_agency.val(null).trigger('change');
                travel_agency.prop('disabled', true);
                frame_agency.val(null).trigger('change');
                frame_agency.prop('disabled', true);
                contract_code.val(null).trigger('change');
                contract_code.prop('disabled', true);
                modality.prop('disabled', true);
                legal_contract.prop('disabled', true);
                bill_state.prop('disabled', true);
                break;
            case 'balance_ttoo_business':
                business.val(null).trigger('change');
                basic_business.val(null).trigger('change');
                rent_lot.val(null).trigger('change');
                travel_agency.val(null).trigger('change');
                travel_agency.prop('disabled', true);
                frame_agency.val(null).trigger('change');
                frame_agency.prop('disabled', true);
                contract_code.val(null).trigger('change');
                contract_code.prop('disabled', true);
                modality.prop('disabled', true);
                legal_contract.prop('disabled', true);
                bill_state.prop('disabled', true);
                break;
            case 'ttoo_and_contract':
                $("#label_travel_agency").addClass('required');
                travel_agency.prop('disabled', false);
                travel_agency.prop('required', true);
                frame_agency.val(null).trigger('change');
                frame_agency.prop('disabled', true);
                contract_code.prop('disabled', false);
                business.val(null).trigger('change');
                basic_business.val(null).trigger('change');
                rent_lot.val(null).trigger('change');
                modality.prop('disabled', true);
                legal_contract.prop('disabled', true);
                bill_state.prop('disabled', true);
                break;
            case 'adjustment_check_errors':
                business.prop('disabled', false);
                business.prop('required', true);
                $("#label_business").addClass('required');
                basic_business.val(null).trigger('change');
                basic_business.prop('disabled', false);
                frame_agency.val(null).trigger('change');
                frame_agency.prop('disabled', true);
                travel_agency.prop('disabled', true);
                legal_contract.prop('disabled', true);
                contract_code.val(null).trigger('change');
                contract_code.prop('disabled', true);
                modality.prop('disabled', true);
                bill_state.prop('disabled', true);
                break;
            case 'billing_summary_by_type':
                bill_state.prop('disabled', true);
                bill_state.val(null).trigger('change');
                modality.prop('disabled', true);
                modality.val([]).trigger('change');
                legal_contract.prop('disabled', true);
                legal_contract.val(null).trigger('change');
                travel_agency.prop('disabled', true);
                travel_agency.val(null).trigger('change');
                contract_code.prop('disabled', true);
                contract_code.val(null).trigger('change');
                break;
            default:
                break;
        }
    }

    defaultEconomyComponents(){
        let rent_adjustment = $("#rent_adjustment");
        let modality = $("#modality");
        let legal_contract = $("#legal_contract");
        let bill_state = $("#bill_state");

        // Otros
        $("#basic_business_id").prop('disabled', true);
        $("#rent_lot_id").prop('disabled', true);
        rent_adjustment.prop('disabled', true);
        modality.prop('disabled', false);
        bill_state.prop('disabled', false);
        legal_contract.prop('disabled', false);
        // Business
        let business = $("#business_id");
        business.prop('disabled', true);
        business.prop('required', false);
        $("#label_business").removeClass('required');

        // Agencias de viajes
        let travel_agency = $("#travel_agency");
        $("#label_travel_agency").removeClass('required');
        travel_agency.prop('disabled', false);
        travel_agency.prop('required', false);
        $("#contract_code").prop('disabled', false);

        let frame_agency = $("#frame_agency");
        frame_agency.prop('disabled', false);
    }
}