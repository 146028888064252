import {Controller} from "stimulus"
import $ from "jquery";
import {myToFixed, toastr_message} from './globalFunctions_controller'

require('block-ui/jquery.blockUI');

export function arraySum(array = []) {
    let sum = 0;
    array.forEach(elem => {
        sum += parseFloat(elem);
    });
    return sum;
}

export default class extends Controller {
    // Tarifas
    amountDays;
    rentTariff;
    releaseTariff;
    supplementTariff;

    // Importes del COME2EC
    originalRent;
    originalRelease;
    originalSupplement;
    originalDrivers;
    originalFuel;
    originalOffice;
    originalDrop;
    originalOther;

    // Importes del SER
    rentImport;
    releaseImport;
    supplementImport;
    driversImport;
    fuelImport;
    officeImport;
    dropImport;
    otherImport;

    // Deudas del SER
    rentDebt;
    releaseDebt;
    supplementDebt;
    driversDebt;
    fuelDebt;
    officeDebt;
    dropDebt;
    otherDebt;

    // Voucher checkboxes
    rentCheck;
    releaseCheck;
    supplementCheck;
    driversCheck;
    fuelCheck;
    officeCheck;
    dropCheck;
    otherCheck;

    // Formas de pago
    $cashAmount;
    $advanceCardAmount;
    $momentCardAmount;
    $voucherAmount;
    $checkAmount;
    $otherAmount;

    // Totales sin Target
    driversTotal;
    driversDiff

    // Globales
    voucherTotal;
    btnAdjust;
    adjustmentForm;
    formChanged;
    paymentAmountMatches;
    breakdownAmountMatches;

    static targets = ['originalRent', 'rentTotal', 'rentDiff', 'originalRelease', 'releaseTotal', 'releaseDiff',
        'originalSupplement', 'supplementTotal', 'supplementDiff', 'originalFuel', 'fuelTotal', 'fuelDiff', 'originalOffice',
        'officeTotal', 'officeDiff', 'originalDrop', 'dropTotal', 'dropDiff', 'originalOther', 'otherTotal', 'otherDiff',
        'import', 'debt', 'total', 'diff'];

    connect() {
        // Importes del COME2EC
        this.originalRent = parseFloat(this.originalRentTarget.textContent);
        this.originalRelease = parseFloat(this.originalReleaseTarget.textContent);
        this.originalSupplement = parseFloat(this.originalSupplementTarget.textContent);
        this.originalDrivers = $('[id*="originalDriverImport"]');
        this.originalFuel = parseFloat(this.originalFuelTarget.textContent);
        this.originalOffice = parseFloat(this.originalOfficeTarget.textContent);
        this.originalDrop = parseFloat(this.originalDropTarget.textContent);
        this.originalOther = parseFloat(this.originalOtherTarget.textContent);

        // Tarifas
        this.amountDays = $('#amountDays');
        this.rentTariff = $('#amountRentTariff');
        this.releaseTariff = $('#amountLiabilityReleaseTariff');
        this.supplementTariff = $('#amountReliefSupplementTariff');

        // Inicializando importes del SER
        this.rentImport = $('#amountRentImport');
        this.releaseImport = $('#amountLiabilityReleaseImport');
        this.supplementImport = $('#amountReliefSupplementImport');
        this.driversImport = $('[id*="driverImport"]');
        this.fuelImport = $('#amountFuelImport');
        this.officeImport = $('#amountOffice');
        this.dropImport = $('#amountDropOff');
        this.otherImport = $('#amountOtherPayments');

        // Inicializando deudas del SER
        this.rentDebt = $('#amountDebtRentImport');
        this.releaseDebt = $('#amountDebtLiabilityReleaseImport');
        this.supplementDebt = $('#amountDebtReliefSupplementImport');
        this.driversDebt = $('[id*="debtAdditionalDriverImport"]');
        this.fuelDebt = $('#amountDebtFuelImport');
        this.officeDebt = $('#amountDebtOfficeImport');
        this.dropDebt = $('#amountDebtDropOffImport');
        this.otherDebt = $('#amountDebtOtherImport');

        // Inicializando checkboxes de Vouchers
        this.rentCheck = $('#voucherRent');
        this.releaseCheck = $('#voucherLiabilityRelease');
        this.supplementCheck = $('#voucherReliefSupplement');
        this.driversCheck = $('[id*="voucherDrivers"]');
        this.fuelCheck = $('#voucherFuel');
        this.officeCheck = $('#voucherOffice');
        this.dropCheck = $('#voucherDropOff');
        this.otherCheck = $('#voucherOtherPayments');

        // Inicicializando formas de pago
        this.$cashAmount = $('#cash');
        this.$advanceCardAmount = $('#card');
        this.$momentCardAmount = $('#moment_card');
        this.$voucherAmount = $('#voucher');
        this.$checkAmount = $('#cheque');
        this.$otherAmount = $('#other');

        this.driversTotal = $('[id*="additionalDriverTotal"]');
        this.driversDiff = $('[id*="additionalDriverDiff"]');

        this.adjustmentForm = $('#adjustment-form');
        this.formChanged = false; // Estado global para controlar si se cambió algún campo
        this.paymentAmountMatches = true; // Estado global para controlar si las sumas de importes coinciden
        this.breakdownAmountMatches = true; // Estado global para controlar si la suma del desglose de conceptos coincide
        this.btnAdjust = $('#btn-adjust');
        this.voucherTotal = parseFloat(this.$voucherAmount.val());

        // Cuando se elimina un cobro que aún no se ha guardado, comprobar las formas de pago
        $(document).on('click', 'button[id*="remove_item"]', this.checkPaymentMethods.bind(this));

        $('input[type="checkbox"][id*="payment_delete_payment"]').on('click', this.removePayment.bind(this));

        this.adjustmentForm.on('keydown', (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        });

        // Chequear cambios en el formulario
        this.adjustmentForm.on('change', (event) => {
            const element = event.target;
            let elementDefaultValue = element.defaultValue;
            let elementValue = element.value;

            if ($(element).attr('type') === 'number') {
                elementDefaultValue = parseFloat(element.defaultValue);
                elementValue = parseFloat(element.value);
                element.value = myToFixed(parseFloat(element.value), 2);
            }

            $(element).toggleClass('bg-changed text-dark', elementDefaultValue !== elementValue);

            $.each($('input'), (_index, inputElement) => {
                if (inputElement.value !== inputElement.defaultValue) {
                    this.formChanged = true;
                    return false;
                } else {
                    this.formChanged = false;
                }
            });

            this.checkPaymentMethods();
            if (element.dataset['paymentId']) {
                this.checkBreakdownSum.call(this, element.dataset['paymentId']);
            }
        });

        // Eventos sobre cambios en tarifas, importes o deudas
        this.amountDays.on('change', this.amountDaysChanged.bind(this));
        this.rentTariff.on('change', this.amountRentTariffChanged.bind(this));
        this.releaseTariff.on('change', this.amountReleaseTariffChanged.bind(this));
        this.supplementTariff.on('change', this.amountSupplementTariffChanged.bind(this));

        this.rentImport
            .add(this.releaseImport)
            .add(this.supplementImport)
            .add(this.rentDebt)
            .add(this.releaseDebt)
            .add(this.supplementDebt)
            .on('change', this.checkTotalSum.bind(this));

        this.driversImport.add(this.driversDebt).on('change', this.driversImportOrDebtChanged.bind(this));
        this.fuelImport.add(this.fuelDebt).on('change', this.fuelImportOrDebtChanged.bind(this));
        this.officeImport.add(this.officeDebt).on('change', this.officeImportOrDebtChanged.bind(this));
        this.dropImport.add(this.dropDebt).on('change', this.dropImportOrDebtChanged.bind(this));
        this.otherImport.add(this.otherDebt).on('change', this.otherImportOrDebtChanged.bind(this));

        // Eventos en checks de Vouchers
        this.rentCheck.on('click', this.rentVoucherCheck.bind(this));
        this.releaseCheck.on('click', this.releaseVoucherCheck.bind(this));
        this.supplementCheck.on('click', this.supplementVoucherCheck.bind(this));
        this.driversCheck.on('click', this.driversVoucherCheck.bind(this));
        this.fuelCheck.on('click', this.fuelVoucherCheck.bind(this));
        this.officeCheck.on('click', this.officeVoucherCheck.bind(this));
        this.dropCheck.on('click', this.dropVoucherCheck.bind(this));
        this.otherCheck.on('click', this.otherVoucherCheck.bind(this));

        // Verificar que la forma de pago por voucher coincida con el total por voucher
        this.$voucherAmount.on('change', () => {
            const alertVoucher = $('#alertVoucher');
            const voucherAmount = parseFloat(this.$voucherAmount.val());

            alertVoucher.text(`La forma de pago Voucher no coincide con el total por voucher: ${this.voucherTotal}.`);
            alertVoucher.toggleClass('d-none', voucherAmount === this.voucherTotal);
            this.$voucherAmount.toggleClass('is-invalid', voucherAmount !== this.voucherTotal)
        });

        // Capturando los campos invalidos en el formulario (tabla de cobros) que pudieran estar ocultos
        $('input').on('invalid', () => {
            toastr_message('error', 'Tiene campos requeridos que debe llenar', 'Error');
        });
    }

    /**
     * @description Cambios en la cantidad de días rentados
     * */
    amountDaysChanged() {
        this.amountRentTariffChanged();
        this.amountReleaseTariffChanged();
        this.amountSupplementTariffChanged();
        this.updateSERTotalAndDifference();
    }

    /**
     * @description Cambios en la tarifa de Renta
     * */
    amountRentTariffChanged() {
        this.rentTotalTarget.textContent = myToFixed(this.rentTariff.val() * this.amountDays.val(), 2);

        if (this.rentCheck.prop('checked')) {
            this.rentImport.val(this.rentTotalTarget.textContent);
        } else {
            this.checkTotalSum();
        }

        if (parseFloat(this.rentTariff.val()) === 0) {
            this.rentImport.val('0.00');
            this.rentDebt.val('0.00');
            this.rentImport.prop('readonly', true);
            this.rentDebt.prop('readonly', true);
        } else {
            this.rentImport.prop('readonly', this.rentCheck.prop('checked'));
            this.rentDebt.prop('readonly', this.rentCheck.prop('checked'));
        }

        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();
    }

    /**
     * @description Cambios en la tarifa de Relevo de responsabilidad
     * */
    amountReleaseTariffChanged() {
        this.releaseTotalTarget.textContent = myToFixed(this.releaseTariff.val() * this.amountDays.val(), 2);

        if (this.releaseCheck.prop('checked')) {
            this.releaseImport.val(this.releaseTotalTarget.textContent);
        } else {
            this.checkTotalSum();
        }

        if (parseFloat(this.releaseTariff.val()) === 0) {
            this.releaseImport.val('0.00');
            this.releaseDebt.val('0.00');
            this.releaseImport.prop('readonly', true);
            this.releaseDebt.prop('readonly', true);
        } else {
            this.releaseImport.prop('readonly', this.releaseCheck.prop('checked'));
            this.releaseDebt.prop('readonly', this.releaseCheck.prop('checked'));
        }

        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();
    }

    /**
     * @description Cambios en la tarifa de Suplemento del relevo
     * */
    amountSupplementTariffChanged() {
        this.supplementTotalTarget.textContent = myToFixed(this.supplementTariff.val() * this.amountDays.val(), 2);

        if (this.supplementCheck.prop('checked')) {
            this.supplementImport.val(this.supplementTotalTarget.textContent);
        } else {
            this.checkTotalSum();
        }

        if (parseFloat(this.supplementTariff.val()) === 0) {
            this.supplementImport.val('0.00');
            this.supplementDebt.val('0.00');
            this.supplementImport.prop('readonly', true);
            this.supplementDebt.prop('readonly', true);
        } else {
            this.supplementImport.prop('readonly', this.supplementCheck.prop('checked'));
            this.supplementDebt.prop('readonly', this.supplementCheck.prop('checked'));
        }

        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();
    }

    /**
     * @description Cambios en el importe o la deuda del Suplemento del relevo
     * */
    driversImportOrDebtChanged() {
        $.each(this.driversImport, (index, item) => {
            let position = index + 1;
            const driverAmount = parseFloat(item.value);
            const driverDebtAmount = parseFloat($(`#debtAdditionalDriverImport${position}`).val());
            const originalAmount = parseFloat($(`#originalDriverImport${position}`).text());
            const amountTotal = driverAmount + driverDebtAmount;
            $(`#additionalDriverTotal${position}`).text(myToFixed(amountTotal, 2));
            $(`#additionalDriverDiff${position}`).text(myToFixed(originalAmount - amountTotal, 2));
        });

        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();
    }

    /**
     * @description Cambios en el importe o la deuda del Combustible
     * */
    fuelImportOrDebtChanged() {
        const fuelImport = parseFloat(this.fuelImport.val());
        const fuelDebt = parseFloat(this.fuelDebt.val());
        this.fuelTotalTarget.textContent = myToFixed(fuelImport + fuelDebt, 2);

        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();
    }

    /**
     * @description Cambios en el importe o la deuda de los Cargos por oficina
     * */
    officeImportOrDebtChanged() {
        const officeImport = parseFloat(this.officeImport.val());
        const officeDebt = parseFloat(this.officeDebt.val());
        this.officeTotalTarget.textContent = myToFixed(officeImport + officeDebt, 2);

        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();
    }

    /**
     * @description Cambios en el importe o la deuda del Drop Off
     * */
    dropImportOrDebtChanged() {
        const dropImport = parseFloat(this.dropImport.val());
        const dropDebt = parseFloat(this.dropDebt.val());
        this.dropTotalTarget.textContent = myToFixed(dropImport + dropDebt, 2);

        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();
    }

    /**
     * @description Cambios en el importe o la deuda de Otros
     * */
    otherImportOrDebtChanged() {
        const otherImport = parseFloat(this.otherImport.val());
        const otherDebt = parseFloat(this.otherDebt.val());
        this.otherTotalTarget.textContent = myToFixed(otherImport + otherDebt, 2);

        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();
    }

    /**
     * @description Cambios en la opción voucher de la Renta
     * */
    rentVoucherCheck() {
        if ($(event.target).prop('checked')) {
            this.rentImport.val(this.rentTotalTarget.textContent);
            this.rentImport.removeClass('bg-changed');
            this.rentDebt.val('0.00');
            this.rentDebt.removeClass('bg-changed');
            this.updateSERImporAndDebt();
            this.rentImport.prop('readonly', true);
            this.rentDebt.prop('readonly', true);
        } else {
            this.rentDebt.prop('readonly', parseFloat(this.rentTariff.val()) === 0);
            this.rentImport.prop('readonly', parseFloat(this.rentTariff.val()) === 0);
        }
        this.checkTotalSum();
    }

    /**
     * @description Cambios en la opción voucher del Relevo de responsabilidad
     * */
    releaseVoucherCheck() {
        if ($(event.target).prop('checked')) {
            this.releaseImport.val(this.releaseTotalTarget.textContent);
            this.releaseImport.removeClass('bg-changed');
            this.releaseDebt.val('0.00');
            this.releaseDebt.removeClass('bg-changed');
            this.updateSERImporAndDebt();
            this.releaseImport.prop('readonly', true);
            this.releaseDebt.prop('readonly', true);
        } else {
            this.releaseImport.prop('readonly', parseFloat(this.releaseTariff.val()) === 0);
            this.releaseDebt.prop('readonly', parseFloat(this.releaseTariff.val()) === 0);
        }
        this.checkTotalSum();
    }

    /**
     * @description Cambios en la opción voucher del Suplemento del relevo
     * */
    supplementVoucherCheck() {
        if ($(event.target).prop('checked')) {
            this.supplementImport.val(this.supplementTotalTarget.textContent);
            this.supplementImport.removeClass('bg-changed');
            this.supplementDebt.val('0.00');
            this.supplementDebt.removeClass('bg-changed');
            this.updateSERImporAndDebt();
            this.supplementImport.prop('readonly', true);
            this.supplementDebt.prop('readonly', true);
        } else {
            this.supplementImport.prop('readonly', parseFloat(this.supplementTariff.val()) === 0);
            this.supplementDebt.prop('readonly', parseFloat(this.supplementTariff.val()) === 0);
        }
        this.checkTotalSum();
    }

    /**
     * @description Cambios en la opción voucher del Combustible
     * */
    fuelVoucherCheck() {
        if ($(event.target).prop('checked')) {
            const fuelImport = parseFloat(this.fuelImport.val()) + parseFloat(this.fuelDebt.val());
            this.fuelImport.val(myToFixed(fuelImport, 2));
            this.fuelDebt.val('0.00');
            this.fuelDebt.removeClass('bg-changed');
        }
        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();

        this.fuelDebt.prop('readonly', $(event.target).prop('checked'));
    }

    /**
     * @description Cambios en la opción voucher de los Cargos por oficina
     * */
    officeVoucherCheck() {
        if ($(event.target).prop('checked')) {
            const officeImport = parseFloat(this.officeImport.val()) + parseFloat(this.officeDebt.val());
            this.officeImport.val(myToFixed(officeImport, 2));
            this.officeDebt.val('0.00');
            this.officeDebt.removeClass('bg-changed');
        }
        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();

        this.officeDebt.prop('readonly', $(event.target).prop('checked'));
    }

    /**
     * @description Cambios en la opción voucher del Drop Off
     * */
    dropVoucherCheck() {
        if ($(event.target).prop('checked')) {
            const dropImport = parseFloat(this.dropImport.val()) + parseFloat(this.dropDebt.val());
            this.dropImport.val(myToFixed(dropImport, 2));
            this.dropDebt.val('0.00');
            this.dropDebt.removeClass('bg-changed');
        }
        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();

        this.dropDebt.prop('readonly', $(event.target).prop('checked'));
    }

    /**
     * @description Cambios en la opción voucher de Otros
     * */
    otherVoucherCheck() {
        if ($(event.target).prop('checked')) {
            const otherImport = parseFloat(this.otherImport.val()) + parseFloat(this.otherDebt.val());
            this.otherImport.val(myToFixed(otherImport, 2));
            this.otherDebt.val('0.00');
            this.otherDebt.removeClass('bg-changed');
        }
        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();

        this.otherDebt.prop('readonly', $(event.target).prop('checked'));
    }

    driversVoucherCheck() {
        $.each(this.driversCheck, (index, item) => {
            let position = index + 1;
            const $driverImport = $(`#driverImport${position}`);
            const $driverDebt = $(`#debtAdditionalDriverImport${position}`);
            if ($(item).prop('checked')) {
                const amount = parseFloat($driverImport.val()) + parseFloat($driverDebt.val());
                $driverImport.val(myToFixed(amount, 2));
                $driverDebt.val('0.00');
                $driverDebt.removeClass('bg-changed');
            }

            this.updateSERImporAndDebt();
            this.updateSERTotalAndDifference();

            $driverDebt.prop('readonly', $(item).prop('checked'));
        });
    }

    /**
     * @description Actualización del total SER y diferencias, comprobando las filas que tienen diferencia
     * */
    updateSERTotalAndDifference() {
        // Chequeando diferencias con respecto al cobro del COME2EC
        const rentTotal = parseFloat(this.rentTotalTarget.textContent);
        $(this.rentTotalTarget.parentNode).toggleClass('table-danger', rentTotal !== this.originalRent);

        const releaseTotal = parseFloat(this.releaseTotalTarget.textContent);
        $(this.releaseTotalTarget.parentNode).toggleClass('table-danger', releaseTotal !== this.originalRelease);

        const supplementTotal = parseFloat(this.supplementTotalTarget.textContent);
        $(this.supplementTotalTarget.parentNode).toggleClass('table-danger', supplementTotal !== this.originalSupplement);

        const driversTotal = arraySum($.map(this.driversTotal, item => item.textContent));
        $.each(this.driversTotal, (index, item) => {
            let position = index + 1;
            const originalDriver = parseFloat($(`[id*="originalDriverImport${position}"]`).text());
            const driverTotal = parseFloat(item.textContent);
            $(item.parentNode).toggleClass('table-danger', driverTotal !== originalDriver);
        });

        const fuelTotal = parseFloat(this.fuelTotalTarget.textContent);
        $(this.fuelTotalTarget.parentNode).toggleClass('table-danger', fuelTotal !== this.originalFuel);

        const officeTotal = parseFloat(this.officeTotalTarget.textContent);
        $(this.officeTotalTarget.parentNode).toggleClass('table-danger', officeTotal !== this.originalOffice);

        const dropTotal = parseFloat(this.dropTotalTarget.textContent);
        $(this.dropTotalTarget.parentNode).toggleClass('table-danger', dropTotal !== this.originalDrop);

        const otherTotal = parseFloat(this.otherTotalTarget.textContent);
        $(this.otherTotalTarget.parentNode).toggleClass('table-danger', otherTotal !== this.originalOther);

        // Calculando diferencias
        this.rentDiffTarget.textContent = myToFixed(this.originalRent - rentTotal, 2);
        const rentDiff = parseFloat(this.rentDiffTarget.textContent);

        this.releaseDiffTarget.textContent = myToFixed(this.originalRelease - releaseTotal, 2);
        const releaseDiff = parseFloat(this.releaseDiffTarget.textContent);

        this.supplementDiffTarget.textContent = myToFixed(this.originalSupplement - supplementTotal, 2);
        const supplementDiff = parseFloat(this.supplementDiffTarget.textContent);

        let driversDiff = 0;
        $.each(this.driversDiff, (index, item) => {
            let position = index + 1;
            const originalImport = parseFloat($(`[id*="originalDriverImport${position}"]`).text());
            const driverImport = parseFloat($(`[id*="driverImport${position}"]`).val());
            const driverDiff = myToFixed(originalImport + driverImport, 2);
            $(item).val(driverDiff);
            driversDiff -= Number(driverDiff);
        });

        this.fuelDiffTarget.textContent = myToFixed(this.originalFuel - fuelTotal, 2);
        const fuelDiff = parseFloat(this.fuelDiffTarget.textContent);

        this.officeDiffTarget.textContent = myToFixed(this.originalOffice - officeTotal, 2);
        const officeDiff = parseFloat(this.officeDiffTarget.textContent);

        this.dropDiffTarget.textContent = myToFixed(this.originalDrop - dropTotal, 2);
        const dropDiff = parseFloat(this.dropDiffTarget.textContent);

        this.otherDiffTarget.textContent = myToFixed(this.originalOther - otherTotal, 2);
        const otherDiff = parseFloat(this.otherDiffTarget.textContent);

        // Total SER
        this.totalTarget.textContent = myToFixed(rentTotal + releaseTotal + supplementTotal + driversTotal +
            fuelTotal + officeTotal + dropTotal + otherTotal, 2);

        // Total diferencia
        this.diffTarget.textContent = myToFixed(rentDiff + releaseDiff + supplementDiff + driversDiff + fuelDiff +
            officeDiff + dropDiff + otherDiff, 2)

        this.updatePaymentForms();
    }

    /**
     * @description Actualización de los totales de importe y deuda
     * */
    updateSERImporAndDebt() {
        // Actualizando total de importes
        const rentImport = parseFloat(this.rentImport.val());
        const releaseImport = parseFloat(this.releaseImport.val());
        const supplementImport = parseFloat(this.supplementImport.val());
        const additionalDriversImport = arraySum($.map(this.driversImport, item => item.value));
        const fuelImport = parseFloat(this.fuelImport.val());
        const officeImport = parseFloat(this.officeImport.val());
        const dropImport = parseFloat(this.dropImport.val());
        const otherImport = parseFloat(this.otherImport.val());

        // Actualizando total de deudas
        const rentDebt = parseFloat(this.rentDebt.val());
        const releaseDebt = parseFloat(this.releaseDebt.val());
        const supplementDebt = parseFloat(this.supplementDebt.val());
        const additionalDriversDebt = arraySum($.map(this.driversDebt, item => item.value));
        const fuelDebt = parseFloat(this.fuelDebt.val());
        const officeDebt = parseFloat(this.officeDebt.val());
        const dropDebt = parseFloat(this.dropDebt.val());
        const otherDebt = parseFloat(this.otherDebt.val());


        this.importTarget.textContent = myToFixed(rentImport + releaseImport + supplementImport + additionalDriversImport + fuelImport +
            officeImport + dropImport + otherImport, 2);

        this.debtTarget.textContent = myToFixed(rentDebt + releaseDebt + supplementDebt + additionalDriversDebt + fuelDebt + officeDebt +
            dropDebt + otherDebt, 2);
    }

    /**
     * @description Comprobación de la suma de importe + deuda = total SER en los conceptos con tarifa * dia
     * */
    checkTotalSum() {
        const rentTotal = parseFloat(this.rentTotalTarget.textContent);
        const rentImport = parseFloat(this.rentImport.val());
        const rentDebt = parseFloat(this.rentDebt.val());
        this.rentImport.toggleClass('is-invalid', rentTotal !== rentImport + rentDebt);
        this.rentDebt.toggleClass('is-invalid', rentTotal !== rentImport + rentDebt)

        const releaseTotal = parseFloat(this.releaseTotalTarget.textContent);
        const releaseImport = parseFloat(this.releaseImport.val());
        const releaseDebt = parseFloat(this.releaseDebt.val());
        this.releaseImport.toggleClass('is-invalid', releaseTotal !== releaseImport + releaseDebt);
        this.releaseDebt.toggleClass('is-invalid', releaseTotal !== releaseImport + releaseDebt)

        const supplementTotal = parseFloat(this.supplementTotalTarget.textContent);
        const supplementImport = parseFloat(this.supplementImport.val());
        const supplementDebt = parseFloat(this.supplementDebt.val());
        this.supplementImport.toggleClass('is-invalid', supplementTotal !== supplementImport + supplementDebt);
        this.supplementDebt.toggleClass('is-invalid', supplementTotal !== supplementImport + supplementDebt)

        this.updateSERImporAndDebt();
        this.updateSERTotalAndDifference();
    }

    updatePaymentForms() {
        let voucherTotal = 0;
        let momentCardTotal = 0;

        const rentTotal = parseFloat(this.rentImport.val());
        if (this.rentCheck.prop('checked')) {
            voucherTotal += rentTotal;
        } else {
            momentCardTotal += rentTotal;
        }

        const releaseTotal = parseFloat(this.releaseImport.val());
        if (this.releaseCheck.prop('checked')) {
            voucherTotal += releaseTotal;
        } else {
            momentCardTotal += releaseTotal;
        }

        const supplementTotal = parseFloat(this.supplementImport.val());
        if (this.supplementCheck.prop('checked')) {
            voucherTotal += supplementTotal;
        } else {
            momentCardTotal += supplementTotal;
        }

        $.each(this.driversCheck, (index, item) => {
            let position = index + 1;
            const driverTotal = parseFloat($(`[id*="additionalDriverTotal${position}"]`).text());
            if ($(item).prop('checked')) {
                voucherTotal += driverTotal;
            } else {
                momentCardTotal += driverTotal;
            }
        });

        const fuelTotal = parseFloat(this.fuelImport.val());
        if (this.fuelCheck.prop('checked')) {
            voucherTotal += fuelTotal;
        } else {
            momentCardTotal += fuelTotal;
        }

        const officeTotal = parseFloat(this.officeImport.val());
        if (this.officeCheck.prop('checked')) {
            voucherTotal += officeTotal;
        } else {
            momentCardTotal += officeTotal;
        }

        const dropTotal = parseFloat(this.dropImport.val());
        if (this.dropCheck.prop('checked')) {
            voucherTotal += dropTotal;
        } else {
            momentCardTotal += dropTotal;
        }

        const otherTotal = parseFloat(this.otherImport.val());
        if (this.otherCheck.prop('checked')) {
            voucherTotal += otherTotal;
        } else {
            momentCardTotal += otherTotal;
        }

        this.voucherTotal = voucherTotal;
        this.$voucherAmount.val(myToFixed(voucherTotal, 2));
        this.$momentCardAmount.val(myToFixed(momentCardTotal, 2));

        this.$voucherAmount.trigger('change');
        this.$momentCardAmount.trigger('change');
    }

    checkPaymentMethods() {
        let amount = 0;
        // let totalCash = 0;
        let totalMomentCard = 0;
        let totalCheck = 0;
        let totalOther = 0;

        const alertPaymentForms = $('#alertPaymentForms');
        // const cashAmount = parseFloat(this.$cashAmount.val());
        const checkAmount = parseFloat(this.$checkAmount.val());
        const momentCardAmount = parseFloat(this.$momentCardAmount.val());
        const otherAmount = parseFloat(this.$otherAmount.val());
        const paymentForms = $('input[class*="_payment_form"][id*="amount"]:not([class*="payment_deleted"])');

        $.each(paymentForms, (_index, item) => {
            amount = parseFloat(item.value);
            switch (item.dataset.code) {
                case 'TM':
                    totalMomentCard += amount;
                    break;
                case 'CHT':
                    totalCheck += amount;
                    break;
                // case 'EFE':
                //     totalCash += amount;
                //     break;
                case 'OTR':
                    totalOther += amount;
                    break;
            }
        });

        this.paymentAmountMatches = (totalCheck === checkAmount) && (totalMomentCard === momentCardAmount) &&
            (totalOther === otherAmount)

        alertPaymentForms.text('Verifique que los importes de cobro de esta sección coincidan con los importes de la forma de pago correspondiente.');
        alertPaymentForms.toggleClass('d-none', this.paymentAmountMatches);

        this.canAdjust();
    }

    removePayment() {
        const paymentId = $(event.target).data('payment-id');
        const affectedRow = $(`tr[id*="payment_${paymentId}"]`);
        const inputAmount = affectedRow.find('input[class*="_payment_form"][id*="amount"]');

        affectedRow.toggleClass('text-decoration-line-through text-danger', $(event.target).prop('checked'));
        inputAmount.toggleClass('payment_deleted', $(event.target).prop('checked'));

        // Deshabilitar los inputs
        affectedRow.find('input').prop('readonly', $(event.target).prop('checked'));
    }

    checkBreakdownSum(id) {
        let breakdownSum = 0;
        const paymentId = id ?? $(event.target).data('payment-id');
        const paymentAmount = parseFloat($(`input[id="amount_${paymentId}"]`).val());
        const paymentConcepts = $(`input[id*="payment-concept-${paymentId}"]`);
        const breakdownAlert = $(`#breakdown-alert-${paymentId}`);

        $.each(paymentConcepts, (_index, concept) => {
            breakdownSum += parseFloat($(concept).val());
        });

        this.breakdownAmountMatches = paymentAmount === breakdownSum;
        breakdownAlert.toggleClass('d-none', this.breakdownAmountMatches);

        this.canAdjust();
    }

    canAdjust() {
        this.btnAdjust
            .toggleClass('disabled', !(this.formChanged && this.paymentAmountMatches && this.breakdownAmountMatches));
    }
}
