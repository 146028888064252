import {Controller} from "stimulus";
import $ from "jquery";
import {ajaxErrorMessages, toastr_message} from "./globalFunctions_controller";

require('block-ui/jquery.blockUI');

function myToFixed(num, precision) {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

export default class extends Controller {
    connect() {
        // const items = $("#sub-commercial-items");
        // const kids = items.children();
        // for (let i= 0; i<=kids.length;i++){
        //     const terminalRef = $(`#payment_terminal_${i}`)
        //     this.setOptions(terminalRef)
        // }
    }


    onChangeFile(e){
       const subcommercial_table = $("#sub_comercials");
        if (e.target.files.length!=0)
        {
            subcommercial_table.hide();
        }else{
            subcommercial_table.show();
        }
    }

    addItems(e) {
        const items = $("#sub-commercial-items");
        const kids = items.children();
        items.append(`
            <tr id='tr-${kids.length + 1}' class="rows">
                <td class="p-0"><input id="sub_commercial_${kids.length + 1}" name="rows[${kids.length + 1}][sub_commercial]" class="form-control p-1 font-smaller text-center" required="true" /></td> 
                <td class="p-0" data-controller="mselect2"><select id="payment_terminal_${kids.length + 1}" name="rows[${kids.length + 1}][payment_terminal][]" class="form-select mc2ec_select payment_terminal_item" multiple="true" required="true"></select></td>
                <td class="p-0"><input id="sales_amount_${kids.length + 1}" name="rows[${kids.length + 1}][sales_amount]" class="form-control p-1 font-smaller text-right sales_item" required="true" pattern="[0-9.]+" value="0.00" data-action="change->subCommercial#totalSales"/></td>
                <td class="p-0"><input id="commission_amount_${kids.length + 1}" name="rows[${kids.length + 1}][commission_amount]" class="form-control p-1 font-smaller text-right commission_item" required="true" pattern="[0-9.]+" value="0.00" data-action="change->subCommercial#totalCommission"/></td>
                <td class="p-0"><input id="net_amount${kids.length + 1}" name="rows[${kids.length + 1}][net_amount]" class="form-control p-1 font-smaller text-right net_item" required="true" pattern="[0-9.]+" value="0.00" data-action="change->subCommercial#totalNetAmount"/></td>
                <td class="p-0" style="display: flex;
              padding-bottom: 0 !important;
              align-items: center;
              justify-content: center;
              height: 34px;">
                  <button id="remove_item_${kids.length + 1}" data-action="click->subCommercial#removeItems" data-value='tr-${kids.length + 1}'
                          type="button" class="border-0 btn btn-outline-danger btn-icon font-smaller" 
                          style="height: 100%;">
                          <span class="fa fa-trash" data-value='tr-${kids.length + 1}'></span>
                  </button>
                </td>
          </tr>
        `);
        const terminalRef = $(`#payment_terminal_${kids.length + 1}`)
        this.setOptions(terminalRef)

    }

    setOptions(terminalRef){
        let $checkboxes = $('input[type="select"]')
        const terminals = JSON.parse(this.data.get("terminals"))
        terminalRef.append('<option value=""> -- Seleccione una terminal-- </option>')
        for (let terminal of Object.keys(terminals)) {
            let option = new Option(terminals[terminal], terminal, false, false);
            terminalRef.append(option);
        }
        // countryRef.select2({
        //     theme: "bootstrap",
        //     language: "es"
        // });
    }

    disabledOptions(e){
        let $selects =  $('select.mc2ec_select');
        for (let select of $selects){
            if(e.target.id!= select.id){
                for (let option of e.selectedOptions){
                    select.options.removeObj(option)
                }
            }
        }
    }


    removeItems(e) {
        let row_item = e.target.dataset.value;
        if (row_item === undefined) {
            parent = e.target.parentNode;
            row_item = parent.dataset.value;
        }
        $(`#${row_item}`).remove();
        this.totalSales();
        this.totalCommission();
        this.totalNetAmount();
    }

    totalSales() {
        let totalSales = 0.00;
        $.each($('.sales_item'), function (key, item) {
            totalSales += parseFloat(item.value)
        });
        $("#sub_total_sales").html(myToFixed(totalSales, 2));
    }

    totalCommission() {
        let totalCommission = 0.00;
        $.each($('.commission_item'), function (key, item) {
            totalCommission += parseFloat(item.value);
        });
        $("#sub_total_commision").html(myToFixed(totalCommission, 2));
    }

    totalNetAmount() {
        let totalNetAmount = 0.00;
        $.each($('.net_item'), function (key, item) {
            totalNetAmount += parseFloat(item.value);
        });
        $("#sub_total_net").html(myToFixed(totalNetAmount, 2));
    }
}